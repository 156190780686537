import React, { Component } from "react";
import { Icon } from "../components/foundation/foundation";
import axios from "axios";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
//import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

import translations_de_DU from "../translations/de_DU.json";
import customConfigFile from "../config/custom.config.json";

import TagManager from "react-gtm-module";

export const PtContext = React.createContext();

export default class PtProvider extends Component {
  constructor() {
    super();

    const apiUrl_dev = "https://weltvonbeyond.ptwd"; // Development API for Valet
    //const apiUrl_dev = "http://127.0.0.1:8000"; // Development API for Laravel built-in server
    const apiUrl_prod = "https://api.weltvonbeyond.com";

    let apiUrl = "";
    let apiVersion = "/api/1.0";
    let env = "dev";
    let debug = true;

    // Check if host is local
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      // Local Development Environment
      apiUrl = apiUrl_dev;
      apiVersion = "/api/1.0";
      env = "dev";
      debug = true;
    } else if (window.location.hostname.substr(0, 3) === "dev") {
      // Development Environment on Production Server / Staging
      apiUrl = apiUrl_prod;
      apiVersion = "/api/1.0";
      env = "dev";
      debug = true;
    } else {
      // Production Environment
      apiUrl = apiUrl_prod;
      apiVersion = "/api/1.0";
      env = "prod";
      debug = false;
    }

    /*console.log("hostname", window.location.hostname);
    console.log("apiUrl", apiUrl);
    console.log("env", env);
    console.log("debug", debug);*/

    // Declare URLs for API
    const apiEndpoints = {
      setting: apiUrl + apiVersion + "/admin/setting",
      signup: apiUrl + apiVersion + "/signup",
      login: apiUrl + "/oauth/token",
      passwordForgotten: apiUrl + apiVersion + "/password/forgotten",
      passwordReset: apiUrl + apiVersion + "/password/reset",
      passwordCheckToken: apiUrl + apiVersion + "/password/token",
      user: apiUrl + apiVersion + "/user",
      saveUser: apiUrl + apiVersion + "/user",
      uploadAvatar: apiUrl + apiVersion + "/user/avatar/upload",
      deleteAvatar: apiUrl + apiVersion + "/user/avatar/delete",
      account: apiUrl + apiVersion + "/user",
      password: apiUrl + apiVersion + "/user/password",
      index: apiUrl + apiVersion + "/index",
      adminEditLock: apiUrl + apiVersion + "/admin/editlock",
      adminDashboard: apiUrl + apiVersion + "/admin/dashboard",
      adminMembers: apiUrl + apiVersion + "/admin/members",
      adminMembersSendConfirmationMassMail:
        apiUrl + apiVersion + "/admin/members/confirmationmails",
      adminPurchase:
        apiUrl + apiVersion + "/admin/members/:memberId/purchases/:purchaseId",
      adminPurchaseToggleActive:
        apiUrl +
        apiVersion +
        "/admin/members/:memberId/purchases/:purchaseId/toggle",
      adminBackground: apiUrl + apiVersion + "/admin/background",
      adminFileUpload: apiUrl + apiVersion + "/admin/fileupload",
      adminFaq: apiUrl + apiVersion + "/admin/faq",
      adminFaqSortCategories: apiUrl + apiVersion + "/admin/faq/sortcategories",
      adminFaqSortEntries: apiUrl + apiVersion + "/admin/faq/sortentries",
      adminLetters: apiUrl + apiVersion + "/admin/letters",
      adminLetterSingleSearch:
        apiUrl + apiVersion + "/admin/letters/single/search",
      adminLivecalls: apiUrl + apiVersion + "/admin/livecalls",
      adminCourses: apiUrl + apiVersion + "/admin/courses",
      adminCourse: apiUrl + apiVersion + "/admin/courses/:id",
      adminCourseLesson:
        apiUrl + apiVersion + "/admin/courses/:courseId/lesson/:lessonId",
      adminCourseImageUpload: apiUrl + apiVersion + "/admin/courses/:id/upload",
      adminCourseCategory:
        apiUrl + apiVersion + "/admin/courses/:courseId/category/:categoryId",
      adminCourseDownload:
        apiUrl + apiVersion + "/admin/courses/:courseId/download/:downloadId",
      quiz: apiUrl + apiVersion + "/quiz",
      quizAnswer: apiUrl + apiVersion + "/quiz/answer",
      publicQuiz: apiUrl + apiVersion + "/public/quiz",
      publicQuizAnswer: apiUrl + apiVersion + "/public/quiz/answer",
      course: apiUrl + apiVersion + "/course/:courseSlug",
      courseLesson:
        apiUrl + apiVersion + "/course/:courseSlug/lesson/:lessonSlug",
      updateLessonStatus:
        apiUrl + apiVersion + "/course/lessonstatus/:lessonId",
      courseLessonNote: apiUrl + apiVersion + "/course/lesson/note",
      courses: apiUrl + apiVersion + "/courses",
      courseDownloadClick:
        apiUrl + apiVersion + "/course/downloads/click/:downloadId",
      publicCourses: apiUrl + apiVersion + "/publiccourses",
      home: apiUrl + apiVersion + "/home",
      achievements: apiUrl + apiVersion + "/achievements",
      letterRead: apiUrl + apiVersion + "/letters/:letterId/read",
      adminCommunity: apiUrl + apiVersion + "/admin/community",
      adminCommunityArea: apiUrl + apiVersion + "/admin/community/area/:id",
      adminCommunityBoard:
        apiUrl + apiVersion + "/admin/community/area/:areaId/:boardId",
      adminCommunitySortAreas:
        apiUrl + apiVersion + "/admin/community/sortareas",
      adminCommunitySortBoards:
        apiUrl + apiVersion + "/admin/community/sortboards",
      communityAreas: apiUrl + apiVersion + "/community/areas",
      communityArea: apiUrl + apiVersion + "/community/areas/:areaSlug",
      communityBoard: apiUrl + apiVersion + "/community/board/:boardSlug",
      communityThread:
        apiUrl + apiVersion + "/community/board/:boardSlug/:threadSlug",
      communityPost: apiUrl + apiVersion + "/community/post/:postId",
      communityLikePost: apiUrl + apiVersion + "/community/post/:postId/like",
      communityNewThread:
        apiUrl + apiVersion + "/community/board/:boardSlug/new",
      communityProfile: apiUrl + apiVersion + "/community/profile/:username",
      communityCheckUsername:
        apiUrl + apiVersion + "/community/checkusername/:username",
      communityModeratorThreadUnlock:
        apiUrl + apiVersion + "/community/moderator/thread/:thread/unlock",
      communityModeratorThreadLock:
        apiUrl + apiVersion + "/community/moderator/thread/:thread/lock",
      communityModeratorThreadDelete:
        apiUrl + apiVersion + "/community/moderator/thread/:thread/delete",
      faq: apiUrl + apiVersion + "/faq",
    };

    // Init State
    this.state = {
      version: "0.2.8",
      lang: "de_DU",
      debug: debug,
      env: env,
      user: {},
      tmpUserId: null,
      apiEndpoints: apiEndpoints,
      auth: {
        grant_type: "password",
        client_id: 1,
        client_secret: "KZ9kPeDi29xelQe0X5NFNSKyvEBKBLfUk1ow5gJO",
        scope: "*",
      },
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      redirectIfNotLoggedIn: "/",
      redirectAfterLogin: "/home",
      redirectAfterLogout: "/",
      redirectIfInactive: "/login?error=inactive",
      urls: {
        impressum: "/impressum",
        datenschutz: "/datenschutz",
      },
      pageTitle: " | Welt von Beyond",
      adminMenuItems: [
        {
          title: "Dashboard",
          name: "dashboard",
          url: "/admin",
          icon: "th-large",
          permission: "ADMIN",
        },
        /*{
          title: "Live Calls",
          name: "livecalls",
          url: "/admin/livecalls",
          icon: "youtube-play",
          permission: "LIVECALLS",
        },*/
        {
          title: "Briefe",
          name: "letters",
          url: "/admin/letters",
          icon: "envelope",
          permission: "LETTERS",
        },
        {
          title: "Kurse",
          name: "courses",
          url: "/admin/courses",
          icon: "graduation-cap",
          permission: "COURSES",
        },
        {
          title: "Community",
          name: "community",
          url: "/admin/community",
          icon: "comments-o",
          permission: "COMMUNITY",
        },
        {
          title: "Mitglieder",
          name: "members",
          url: "/admin/members",
          icon: "users",
          permission: "MEMBERS",
        },
        {
          title: "FAQ",
          name: "faq",
          url: "/admin/faq",
          icon: "question-circle",
          permission: "FAQ",
        },
        {
          title: "Hintergründe",
          name: "backgrounds",
          url: "/admin/backgrounds",
          icon: "picture-o",
          permission: "ADMIN",
        },
        {
          title: "Einstellungen",
          name: "settings",
          url: "/admin/settings",
          icon: "cogs",
          permission: "ADMIN",
        },
      ],
      tooltipSettings: {
        position: "top",
        type: "dark",
        effect: "solid",
      },
      editLock: {
        timeout: 50, // Seconds
        result: { status: "UNKNOWN", name: "", intervalId: 0 },
      },
      gaId: "UA-86878012-4", // Google Analytics
      gtmId: "GTM-NGD88DH",
      fbpId: "358892354583187", // Facebook Pixel
      months: {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
      monthsShort: {
        1: "Jan",
        2: "Feb",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "Aug",
        9: "Sep",
        10: "Okt",
        11: "Nov",
        12: "Dez",
      },
      weekdays: {
        1: "Montag",
        2: "Dienstag",
        3: "Mittwoch",
        4: "Donnerstag",
        5: "Freitag",
        6: "Samstag",
        7: "Sonntag",
      },
      weekdaysShort: {
        1: "Mo",
        2: "Di",
        3: "Mi",
        4: "Do",
        5: "Fr",
        6: "Sa",
        7: "So",
      },
      genders: {
        null: "",
        f: "weiblich",
        m: "männlich",
        d: "divers",
      },
      livecallTypes: {
        vimeo: {
          title: "Vimeo",
          icon: "vimeo-square",
        },
        youtube: {
          title: "Youtube",
          icon: "youtube",
        },
      },
      videoTypes: {
        vimeo: "Vimeo",
        youtube: "Youtube",
        audio: "Audiodatei",
      },
      downloadTypes: {
        pdf: {
          title: "PDF",
          icon: "file-pdf-o",
        },
        audio: {
          title: "AUDIO",
          icon: "file-audio-o",
        },
        image: {
          title: "IMAGE",
          icon: "file-image-o",
        },
      },
      purchaseTypes: {
        admin: {
          title: "Admin",
        },
        digistore: {
          title: "Digistore",
        },
      },
    };
  }

  render() {
    return (
      <PtContext.Provider
        value={{
          ...this.state,
          init: this.init,
          getVersion: this.getVersion,
          isDebug: this.isDebug,
          getHeaders: this.getHeaders,
          hasAccessToken: this.hasAccessToken,
          initAuth: this.init_auth,
          initFinished: this.initFinished,
          login: this.login,
          updateUser: this.handle__updateUser,
          handleLogout: this.handle__logout,
          hideLoadingScreen: this.hideLoadingScreen,
          redirectAfterLogin: this.redirectAfterLogin,
          redirectAfterLogout: this.redirectAfterLogout,
          redirectIfNotLoggedIn: this.redirectIfNotLoggedIn,
          loading: this.loading,
          notfound: this.notfound,
          setPageTitle: this.setPageTitle,
          isSaving: this.isSaving,
          setIsSaving: this.setIsSaving,
          setSavingType: this.setSavingType,
          getSavingType: this.getSavingType,
          setSavingProgress: this.setSavingProgress,
          getSavingProgress: this.getSavingProgress,
          finishSaving: this.finishSaving,
          getFullName: this.getFullName,
          isUserLoggedIn: this.isUserLoggedIn,
          getTmpUserId: this.getTmpUserId,
          setTmpUserId: this.setTmpUserId,
          getUser: this.getUser,
          isAdmin: this.isAdmin,
          isCommunityModerator: this.isCommunityModerator,
          getToolTipSetting: this.getToolTipSetting,
          getEditLockTimeout: this.getEditLockTimeout,
          editLock: this.editLock,
          cancelEditLock: this.cancelEditLock,
          showMsgEditLocked: this.showMsgEditLocked,
          createNotifictation: this.createNotifictation,
          formatDateForSaving: this.formatDateForSaving,
          formatDatetimeForSaving: this.formatDatetimeForSaving,
          getGenders: this.getGenders,
          getGender: this.getGender,
          getVideoTypes: this.getVideoTypes,
          getVideoType: this.getVideoType,
          getLivecallTypes: this.getLivecallTypes,
          getLivecallType: this.getLivecallType,
          getDownloadTypes: this.getDownloadTypes,
          getDownloadType: this.getDownloadType,
          getPurchaseTypes: this.getPurchaseTypes,
          getPurchaseType: this.getPurchaseType,
          apiUrl: this.apiUrl,
          custom: this.custom,
          checkError: this.checkError,
          setting: this.setting,
          handleError: this.handleError,
          translate: this.translate,

          createEmptyPost: this.createEmptyPost,
          createEmptyThread: this.createEmptyThread,

          handleApiResponse: this.handleApiResponse,
        }}
      >
        <ReactNotification />
        {this.props.children}
      </PtContext.Provider>
    );
  }

  init = (pageTitle) => {
    const { env, gaId, fbpId, gtmId } = this.state;

    if (env === "prod") {
      /*if (gaId) {
        ReactGA.initialize(gaId, {
          debug: false,
          gaOptions: {
            siteSpeedSampleRate: 100,
          },
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
      }*/

      const tagManagerArgs = {
        gtmId: gtmId,
      };

      TagManager.initialize(tagManagerArgs);

      if (fbpId) {
        const advancedMatching = {};
        const options = {
          autoConfig: true, // set pixel's autoConfig
          debug: false, // enable logs
        };
        ReactPixel.init(fbpId, advancedMatching, options);

        ReactPixel.pageView();
      }
    }

    if (pageTitle) {
      this.setPageTitle(pageTitle);
    }
  };

  getVersion = () => {
    const { version } = this.state;
    return version;
  };

  isDebug = () => {
    const { debug } = this.state;
    if (debug === true) {
      return true;
    }
    return false;
  };

  getHeaders = () => {
    const { headers } = this.state;
    return headers;
  };

  hasAccessToken = () => {
    if (localStorage.getItem("access_token")) {
      return true;
    }
    return false;
  };

  login = (callback) => {
    if (this.hasAccessToken()) {
      axios
        .get(this.state.apiEndpoints.user, {
          headers: this.getHeaders(),
        })
        .then((response) => {
          try {
            const { user } = response.data;
            if (user !== null) {
              this.handle__updateUser(user);
            }
          } catch {}

          if (callback) {
            callback();
          }
        })
        .catch((error) => {
          if (this.isDebug()) {
            console.log("Error", error);
          }

          if (callback) {
            callback();
          }
        });
    } else {
      if (callback) {
        callback();
      }
    }
  };

  handleApiResponse = (apiResponse, finishInit, callback) => {
    const { user, app } = apiResponse;

    if (user) {
      if (user.active === false) {
        localStorage.removeItem("access_token");
        window.location = this.state.redirectIfInactive;
      } else {
        this.setState({ user });
      }
    }

    if (app) {
      this.setState({ app }, () => {});
    }

    if (finishInit === true) {
      this.initFinished();
    }

    if (callback) {
      callback();
    }
  };

  handle__updateUser = (user, callback) => {
    if (user) {
      if (user.active === false) {
        localStorage.removeItem("access_token");
        window.location = this.state.redirectIfInactive;
      } else {
        this.setState({ user });
        if (callback) {
          callback();
        }
      }
    }
  };

  handle__logout = () => {
    localStorage.removeItem("access_token");
    window.location = this.state.redirectAfterLogout;
  };

  handle__updateAccessToken = () => {
    const { headers } = this.state;
    headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    this.setState({ headers: headers });
  };

  initFinished = () => {
    this.hideLoadingScreen();
  };

  hideLoadingScreen = () => {
    document.getElementById("loadingScreen").style.display = "none";
  };

  showLoadingScreen = () => {
    document.getElementById("loadingScreen").style.display = "block";
  };

  redirectAfterLogin = () => {
    let goTo = this.state.redirectAfterLogin;
    /*if (localStorage.getItem("page_after_login")) {
      goTo = localStorage.getItem("page_after_login");
      localStorage.removeItem("page_after_login");
    }*/
    window.location = goTo;
  };

  redirectAfterLogout = () => {
    window.location = this.state.redirectAfterLogout;
  };

  redirectIfNotLoggedIn = () => {
    window.location = this.state.redirectIfNotLoggedIn;
  };

  loading = (isError) => {
    if (isError === true) {
      return (
        <div className="loading">
          <p>
            <Icon icon="exclamation-triangle" />
          </p>
          <div>
            <strong>Fehler beim Laden.</strong>
            <br />
            Bitte später erneut versuchen!
          </div>
        </div>
      );
    } else {
      return (
        <div className="loading">
          <p>
            <Icon icon="spin fa-circle-o-notch" />
          </p>
        </div>
      );
    }
  };

  notfound = (message) => {
    let text = "Eintrag nicht gefunden.";
    if (message) {
      text = message;
    }

    return (
      <div className="loading">
        <p>
          <Icon icon="question-circle-o" />
        </p>
        <div>
          <strong>{text}</strong>
          <br />
          Bitte gehe zurück und wähle einen anderen Eintrag.
        </div>
      </div>
    );
  };

  setPageTitle = (title) => {
    const { pageTitle } = this.state;

    if (title) {
      document.title = `${title}${pageTitle}`;
    }
  };

  /* SAVING MODAL */
  isSaving = () => {
    const { isSaving } = this.state;
    return isSaving;
  };

  setIsSaving = (value, time) => {
    if (value === true || value === false) {
      this.setState({ isSaving: value }, () => {
        if (parseInt(time) > 0) {
          this.startSavingTimer(time);
        }
      });
    }
  };

  startSavingTimer(time) {
    time = parseInt(time);
    const timeFactor = 0.5;
    const percentPerStep = 100 / (time / timeFactor); // (100% / seconds)
    //console.log(percentPerStep);
    let percent = 5;
    this.setSavingProgress(percent);

    let savingTimer = window.setInterval(() => {
      if (this.getSavingProgress() < 100) {
        percent += percentPerStep;
        //console.log(percent);
        this.setSavingProgress(percent);
      }

      if (percent + percentPerStep >= 100) {
        clearInterval(savingTimer);
      }
    }, 1000 * timeFactor);
  }

  finishSaving = () => {
    this.setSavingProgress(100);
  };

  setSavingType = (type) => {
    this.setState({ savingType: type });
  };

  getSavingType = () => {
    const { savingType } = this.state;
    return savingType;
  };

  setSavingProgress = (percent) => {
    this.setState({ savingProgress: percent });
  };

  getSavingProgress = () => {
    const { savingProgress } = this.state;
    return savingProgress;
  };

  isUserLoggedIn = () => {
    const { user } = this.state;
    let isLoggedIn = false;

    if (user && user.id && user.email) {
      isLoggedIn = true;
    }

    return isLoggedIn;
  };

  getTmpUserId = () => {
    let { tmpUserId } = this.state;
    const lsTmpUserId = localStorage.getItem("tmp_user_id");

    if (!tmpUserId) {
      if (lsTmpUserId) {
        tmpUserId = lsTmpUserId;
        this.setTmpUserId(tmpUserId);
      }
    }

    return tmpUserId;
  };

  setTmpUserId = (newTmpUserId) => {
    localStorage.setItem("tmp_user_id", newTmpUserId);
    this.setState({ tmpUserId: newTmpUserId });
  };

  getUser = () => {
    const { user } = this.state;
    if (this.isUserLoggedIn() === true) {
      return user;
    } else {
      // Check if Tmp User Id
      const tmpUserId = localStorage.getItem("tmp_user_id");
      if (tmpUserId) {
        this.setTmpUserId(tmpUserId);
      }
      return null;
    }
  };

  getFullName = (givenUser) => {
    let { user } = this.state;
    let userName = "unbekannt";

    if ((!user || !user.id) && !givenUser) {
      return "";
    } else if (givenUser) {
      user = givenUser;
    }

    if (user.firstname && user.lastname) {
      userName = `${user.firstname} ${user.lastname}`;
    } else if (user.firstname && !user.lastname) {
      userName = user.firstname;
    } else if (user.lastname && !user.firstname) {
      if (user.gender) {
        if (user.gender === "m") {
          userName = `Herr ${user.lastname}`;
        } else if (user.gender === "f") {
          userName = `Frau ${user.lastname}`;
        }
      } else {
        userName = `Frau/Herr ${user.lastname}`;
      }
    }

    return userName;
  };

  isAdmin = () => {
    const { user } = this.state;

    if (
      user &&
      user.admin &&
      user.admin.is_admin &&
      user.admin.is_admin === true
    ) {
      return true;
    }

    return false;
  };

  isCommunityModerator = () => {
    const { user } = this.state;

    if (
      user &&
      user.admin &&
      user.admin.is_admin &&
      user.admin.is_admin === true &&
      user.admin.is_community_moderator &&
      user.admin.is_community_moderator === true
    ) {
      return true;
    }

    return false;
  };

  getToolTipSetting = (type) => {
    const { tooltipSettings } = this.state;

    if (type === "position") {
      return tooltipSettings.position;
    } else if (type === "type") {
      return tooltipSettings.type;
    } else if (type === "effect") {
      return tooltipSettings.effect;
    }
  };

  getEditLockTimeout = () => {
    const { editLock } = this.state;
    return editLock.timeout;
  };

  editLock = async (type, entry_id) => {
    const { apiEndpoints } = this.state;

    let axiosResult = await axios
      .post(
        apiEndpoints.adminEditLock + "/" + type + "/" + entry_id,
        {},
        {
          headers: this.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { editLock } = this.state;

          editLock.result.type = type;
          editLock.result.entry_id = entry_id;

          if (response.data.status === "SUCCESS") {
            editLock.result.status = "SUCCESS";
          } else if (response.data.status === "LOCKED") {
            editLock.result.status = "LOCKED";
            editLock.result.name = response.data.name;
          }
          this.setState({ editLock }, () => {
            if (response.data.status === "SUCCESS") {
              let intervalId = window.setInterval(
                this.setEditLockTimer,
                this.getEditLockTimeout() * 1000
              );
              editLock.result.intervalId = intervalId;
              this.setState({ editLock });
            }
          });

          if (this.isDebug()) {
            console.log("RESPONSE DATA", response.data);
          }
          return editLock.result;
        } catch {
          const { editLock } = this.state;
          editLock.result.status = "ERROR";
          this.setState({ editLock });
          alert(
            "Admin Edit Lock - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
          return editLock.result;
        }
      })
      .catch((error) => {
        if (this.isDebug()) {
          console.log("Error", error);
        }
      });

    //console.log("axiosresult", axiosResult);

    return axiosResult;
  };

  setEditLockTimer = () => {
    const { editLock } = this.state;

    this.updateEditLock(editLock.result.type, editLock.result.entry_id);
  };

  updateEditLock = (type, entry_id) => {
    const { apiEndpoints } = this.state;

    axios
      .post(
        apiEndpoints.adminEditLock + "/" + type + "/" + entry_id,
        {},
        {
          headers: this.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { editLock } = this.state;

          if (response.data.status === "SUCCESS") {
            editLock.result.status = "SUCCESS";
            editLock.result.name = response.data.name;
          } else if (response.data.status === "LOCKED") {
            editLock.result.status = "LOCKED";
            editLock.result.name = response.data.name;
          }
          this.setState({ editLock });

          if (this.isDebug()) {
            console.log("RESPONSE DATA", response.data);
          }
        } catch {
          const { editLock } = this.state;
          editLock.result.status = "ERROR";
          this.setState({ editLock });
          alert(
            "Admin Edit Lock - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (this.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  cancelEditLock = () => {
    const { apiEndpoints, editLock } = this.state;

    window.clearInterval(editLock.result.intervalId);

    axios
      .delete(
        apiEndpoints.adminEditLock +
          "/" +
          editLock.result.type +
          "/" +
          editLock.result.entry_id,
        {
          headers: this.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { editLock } = this.state;
          editLock.result.status = "UNKWOWN";
          editLock.result.name = undefined;

          this.setState({ editLock });

          if (this.isDebug()) {
            console.log("RESPONSE DATA", response.data);
          }
        } catch {
          const { editLock } = this.state;
          editLock.result.status = "ERROR";
          this.setState({ editLock });
          alert(
            "Admin Cancel Edit Lock - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (this.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  getEditLockResult = () => {
    const { editLock } = this.state;
    return editLock.result;
  };

  showMsgEditLocked = (message) => {
    let text = "Dieser Eintrag wird gerade bearbeitet.";
    if (message) {
      text = message;
    }

    return (
      <div className="warning callout text-center">
        <strong>
          <Icon icon="exclamation-triangle" left />
          {text}
        </strong>
      </div>
    );
  };

  createNotifictation = (title, message, type, position, show) => {
    if (!title) {
      title = null;
    }

    if (!message) {
      message = null;
    }

    if (!type) {
      type = "info";
    }

    if (!position) {
      position = "top-right";
    }

    if (!show) {
      show = 5;
    }

    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: position,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: show * 1000,
        onScreen: false,
      },
    });
  };

  formatDateForSaving = (dateObj) => {
    if (dateObj) {
      try {
        return (
          ("0" + dateObj.getDate()).slice(-2) +
          "." +
          ("0" + (dateObj.getMonth() + 1)).slice(-2) +
          "." +
          dateObj.getFullYear()
        );
      } catch {
        try {
          dateObj = new Date(dateObj);
          return (
            ("0" + dateObj.getDate()).slice(-2) +
            "." +
            ("0" + (dateObj.getMonth() + 1)).slice(-2) +
            "." +
            dateObj.getFullYear()
          );
        } catch {
          return dateObj;
        }
      }
    }
  };

  formatDatetimeForSaving = (dateObj) => {
    if (dateObj) {
      try {
        return (
          ("0" + dateObj.getDate()).slice(-2) +
          "." +
          ("0" + (dateObj.getMonth() + 1)).slice(-2) +
          "." +
          dateObj.getFullYear() +
          " - " +
          ("0" + dateObj.getHours()).slice(-2) +
          ":" +
          ("0" + dateObj.getMinutes()).slice(-2)
        );
      } catch {
        try {
          dateObj = new Date(dateObj);
          return (
            ("0" + dateObj.getDate()).slice(-2) +
            "." +
            ("0" + (dateObj.getMonth() + 1)).slice(-2) +
            "." +
            dateObj.getFullYear() +
            " - " +
            ("0" + dateObj.getHours()).slice(-2) +
            ":" +
            ("0" + dateObj.getMinutes()).slice(-2)
          );
        } catch {
          return dateObj;
        }
      }
    }
  };

  getGenders = () => {
    const { genders } = this.state;
    return genders;
  };

  getGender = (gender) => {
    const { genders } = this.state;

    if (genders[gender]) {
      return genders[gender];
    } else {
      return gender;
    }
  };

  getVideoTypes = () => {
    const { videoTypes } = this.state;
    return videoTypes;
  };

  getVideoType = (videoType) => {
    const { videoTypes } = this.state;

    if (videoTypes[videoType]) {
      return videoTypes[videoType];
    } else {
      return videoType;
    }
  };

  getLivecallTypes = () => {
    const { livecallTypes } = this.state;
    return livecallTypes;
  };

  getLivecallType = (type) => {
    const { livecallTypes } = this.state;
    if (livecallTypes[type]) {
      return (
        <span>
          <Icon icon={livecallTypes[type].icon} left />{" "}
          {livecallTypes[type].title}
        </span>
      );
    } else {
      return type;
    }
  };

  getPurchaseTypes = () => {
    const { purchaseTypes } = this.state;
    return purchaseTypes;
  };

  getPurchaseType = (type) => {
    const { purchaseTypes } = this.state;
    if (purchaseTypes[type]) {
      return (
        <span>
          <Icon icon={purchaseTypes[type].icon} left />{" "}
          {purchaseTypes[type].title}
        </span>
      );
    } else {
      return type;
    }
  };

  getDownloadTypes = (type) => {
    const tmpDownloadTypes = this.state.downloadTypes;
    let downloadTypes = {
      null: "",
    };

    // For Select Values
    if (type === "select") {
      Object.keys(tmpDownloadTypes).map((downloadTypeKey) => {
        downloadTypes[downloadTypeKey] =
          tmpDownloadTypes[downloadTypeKey].title;
        return null;
      });
    } else {
      downloadTypes = tmpDownloadTypes;
    }

    return downloadTypes;
  };

  getDownloadType = (type) => {
    const { downloadTypes } = this.state;
    if (downloadTypes[type]) {
      return (
        <span>
          <Icon icon={downloadTypes[type].icon} left />{" "}
          {downloadTypes[type].title}
        </span>
      );
    } else {
      return type;
    }
  };

  custom = (path) => {
    const { env } = this.state;
    let customConfig = {};

    /*if (env === "prod") {
      customConfig = this.state.app.custom;
    } else {*/
    customConfig = customConfigFile;
    /*}*/

    let result = customConfig;

    try {
      let pathSteps = path.split(".");

      pathSteps.map((step) => {
        result = result[step];
        return null;
      });
    } catch (exception) {
      result = path;
    }

    //console.log("custom", result);

    return result;
  };

  apiUrl = (type, params) => {
    const { apiEndpoints } = this.state;
    let url;

    if (apiEndpoints[type]) {
      url = apiEndpoints[type];

      if (params) {
        Object.keys(params).map((key) => {
          url = url.replace(`:${key}`, params[key]);
          return null;
        });
      }
    }

    return url;
  };

  checkError = (error) => {
    if (error && error.response && error.response.status) {
      if (error.response.status === 401) {
        window.location.href = "/login";
      }
    }
  };

  setting = (appSettingKey, defaultValue) => {
    const { app } = this.state;
    if (app && app.settings) {
      const appSettings = app.settings;
      if (appSettings[appSettingKey]) {
        return appSettings[appSettingKey].value;
      }
    }

    if (defaultValue) {
      return defaultValue;
    } else {
      return null;
    }
  };

  handleError = (error, where) => {
    if (this.isDebug()) {
      console.log("ERROR", error, where);
    }
    return null;
  };

  translate = (path, parameters, language) => {
    if (!language) {
      language = this.state.lang;
    }

    let languageFile, result;

    if (language === "de_DU") {
      languageFile = translations_de_DU;
    }

    result = languageFile;

    try {
      let pathSteps = path.split(".");

      pathSteps.map((step) => {
        result = result[step];
        return null;
      });
    } catch (exception) {
      result = path;
    }

    if (!result || result === path || result === languageFile) {
      result = path;
      if (this.isDebug()) {
        console.log(`Missing translation: [${path}]`);
      }
    } else {
      if (parameters) {
        Object.keys(parameters).map((parameterKey) => {
          result = result.replace(
            `{${parameterKey}}`,
            parameters[parameterKey]
          );
          return null;
        });
      }
    }

    return result;
  };

  createEmptyPost = (thread) => {
    let threadId;
    if (thread && thread.id) {
      threadId = thread.id;
    } else {
      threadId = "new";
    }

    const post = {
      id: "new",
      thread_id: threadId,
      text: "",
      text_html: "",
    };

    return post;
  };

  createEmptyThread = (board) => {
    const thread = {
      id: "new",
      board_id: board.id,
      name: "",
    };

    return thread;
  };
}

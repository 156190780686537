import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import Avatar from "../../user/Avatar";
import { Icon } from "../../foundation/_buttons";
import qs from "query-string";
import { isUndefined } from "util";
import { cloneDeep } from "lodash";
import ReactTooltip from "react-tooltip";
import PTPopup from "../../ptPopup/PTPopup";

class AdminMembers extends Component {
  state = {
    baseUrl: "/admin/members",
    loadingError: false,
    members: undefined,
    courses: undefined,
    search: {
      show: 100,
      page: 1,
      search: "",
      course: 0,
    },
    pages: 0,
    page: 0,
    total: 0,
    isSearching: false,
    sendingStatus: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      const queryParams = qs.parse(this.props.location.search);
      const { search } = this.state;

      if (Object.keys(queryParams).length > 0) {
        Object.keys(search).map((key) => {
          if (!isUndefined(queryParams[key])) {
            search[key] = queryParams[key];
          }
          return null;
        });

        this.setState({ search }, () => {
          this.init();
        });
      } else {
        this.init();
      }
    }
  }

  componentDidMount() {
    const queryParams = qs.parse(this.props.location.search);
    const { search } = this.state;

    if (Object.keys(queryParams).length > 0) {
      Object.keys(search).map((key) => {
        if (!isUndefined(queryParams[key])) {
          search[key] = queryParams[key];
        }
        return null;
      });

      this.setState({ search }, () => {
        this.init();
      });
    } else {
      this.init();
    }

    this.props.context.setPageTitle("Mitgliederverwaltung");
  }

  render() {
    return (
      <AdminLayout
        nav="members"
        breadcrumbs={["admin_dashboard", "admin_members"]}
      >
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showSearch()}
        {this.view__showPerPage()}
        {this.view__showListButtons()}
        {this.view__showList()}
        {this.view__showSendingPopup()}
      </AdminLayout>
    );
  }

  view__showHeadline() {
    return <h1>Mitglieder</h1>;
  }

  view__showPageActions() {
    const { baseUrl } = this.state;

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <a
            href={`${baseUrl}/new`}
            className="button"
            data-tip="Neues Mitglied anlegen"
          >
            <Icon icon="plus" left /> Mitglied anlegen
          </a>
        </div>
      </React.Fragment>
    );
  }

  view__showSendingPopup() {
    const { sendingStatus, totalSent } = this.state;
    if (
      sendingStatus === "SENDING" ||
      sendingStatus === "SENT" ||
      sendingStatus === "ERROR"
    ) {
      return (
        <PTPopup
          show={true}
          size="small"
          hideCloseButton={true}
          handleClose={() => {}}
        >
          <h3>Versand Bestätigungsmail</h3>
          {sendingStatus === "SENDING" ? (
            <>
              <p>
                Die Mails werden versendet. Das kann je nach Anzahl der zu
                versendenden Mails einige Sekunden bis einige Minuten dauern.
              </p>
              <p className="text-center" style={{ fontSize: "1.4rem" }}>
                <Icon icon="circle-o-notch fa-spin" />
              </p>
            </>
          ) : null}
          {sendingStatus === "SENT" ? (
            <div className="success callout">
              <p>
                <strong>Mailversand abgeschlossen</strong>
              </p>
              <p>Es wurden insgesamt {totalSent} Mails versendet.</p>
              <div className="text-center">
                <button
                  className="small primary hollow button"
                  onClick={() =>
                    this.setState({ totalSent: 0, sendingStatus: null })
                  }
                >
                  <Icon icon="check" left /> Schließen
                </button>
              </div>
            </div>
          ) : null}
          {sendingStatus === "ERROR" ? (
            <div className="alert callout">
              <p>
                <strong>Fehler beim Mailversand</strong>
              </p>
              <p>
                Beim Versand der Mails ist ein Fehler aufgetreten.
                Höchstwahrscheinlich ein Überschreiten des Zeitlimits. Weitere
                Informationen finden sich in den Errorlogs auf dem Server.
              </p>
              <div className="text-center">
                <button
                  className="small primary hollow button"
                  onClick={() =>
                    this.setState({ totalSent: 0, sendingStatus: null })
                  }
                >
                  <Icon icon="check" left /> Schließen
                </button>
              </div>
            </div>
          ) : null}
        </PTPopup>
      );
    }
  }

  view__showSearch() {
    const { search, courses, isSearching } = this.state;

    return (
      <div className="admin-member-search">
        <Box>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <input
                  type="text"
                  value={search.search}
                  placeholder="Suche nach E-Mail/Name"
                  onChange={(e) => {
                    search.search = e.target.value;
                    this.setState({ search });
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.search();
                    }
                  }}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={16} md={12}>
                {courses ? (
                  <select
                    value={search.course}
                    onChange={(e) => {
                      search.course = e.target.value;
                      this.setState({ search });
                    }}
                  >
                    <option value={0}>--- Alle Kurse ---</option>
                    {courses.map((course) => {
                      return (
                        <option key={course.id} value={course.id}>
                          {course.name}
                        </option>
                      );
                    })}
                  </select>
                ) : null}
              </Cell>
              <Cell sm={8} md={4} mdo={8}>
                <button
                  className="primary button"
                  style={{ width: "100%" }}
                  onClick={this.search}
                >
                  {isSearching === true ? (
                    <Icon icon="circle-o-notch fa-spin" />
                  ) : (
                    <>
                      <Icon icon="search" left /> Suchen
                    </>
                  )}
                </button>
              </Cell>
            </Row>
          </Grid>
        </Box>
      </div>
    );
  }

  view__showPerPage() {
    const { search } = this.state;

    const selectValues = {
      10: 10,
      25: 25,
      50: 50,
      100: 100,
      200: 200,
      9999999: "alle",
    };

    if (!selectValues[search.show]) {
      selectValues[search.show] = search.show;
    }

    return (
      <div className="admin-pagination-per-page">
        <select
          value={search.show}
          onChange={(e) => {
            search.show = e.target.value;
            search.page = 1;
            this.props.history.push(this.generateUrl(search));
          }}
        >
          {Object.keys(selectValues).map((key, index) => {
            return (
              <option key={index} value={key}>
                {selectValues[key]}
              </option>
            );
          })}
        </select>{" "}
        pro Seite
      </div>
    );
  }

  view__showListButtons() {
    const _c = this.props.context;

    return (
      <div className="members-list-buttons">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <button
          className="primary hollow button"
          data-tip="Bestätigungsmail erneut zusenden"
          onClick={this.handle__sendConfirmationMail}
        >
          <Icon icon="envelope-o" />
        </button>
      </div>
    );
  }

  view__showPagination(where) {
    const { search, pages, page } = this.state;

    let pagesObject = [];
    for (let i = 1; i <= pages; i++) {
      let classes = `pagination-page`;
      if (parseInt(i) === parseInt(page)) {
        classes = `current ${classes}`;
      }

      let pageUrlInfo = cloneDeep(search);
      pageUrlInfo.page = i;
      let pageUrl = this.generateUrl(pageUrlInfo);

      pagesObject.push(
        <Link to={pageUrl} className={classes}>
          {i}
        </Link>
      );
    }

    return (
      <div className={`${where} admin-pagination`}>
        {pagesObject.map((paginationPage, index) => {
          return <React.Fragment key={index}>{paginationPage}</React.Fragment>;
        })}
      </div>
    );
  }

  view__showList() {
    const { members, loadingError, total } = this.state;
    const _c = this.props.context;

    if (!members) {
      return _c.loading(loadingError);
    }

    if (members && isArray(members) && members.length === 0) {
      return <p className="text-center">Keine Mitglieder gefunden.</p>;
    }

    return (
      <React.Fragment>
        <p className="text-center">
          {`${total} ${total === 1 ? `Mitglied` : `Mitglieder`} gefunden.`}
        </p>
        {this.view__showPagination("top")}
        <Box size="nopadding" margin={false}>
          {this.view__showListTitle()}
          {this.view__showListDesktop(members)}
          {this.view__showListMobile(members)}
        </Box>
        {this.view__showPagination("bottom")}
      </React.Fragment>
    );
  }

  view__showListTitle() {
    return (
      <div className="admin-list-title">
        <Grid type="full">
          <Row>
            <Cell md={2} className="text-right">
              <div className="padding">ID</div>
            </Cell>
            <Cell md={2}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={12}>
              <div className="padding">Name</div>
            </Cell>
            <Cell md={4} className="text-center">
              <div className="padding">Registrierung</div>
            </Cell>
            <Cell md={4} className="text-center">
              <div className="padding">Letzter Login</div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop(members) {
    const { baseUrl } = this.state;
    const _c = this.props.context;

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {members.map((member, index) => (
            <li className="admin-list-item" key={index}>
              <Link to={`${baseUrl}/${member.id}`} className="clickable">
                <Grid type="full">
                  <Row>
                    <Cell md={2} className="text-right">
                      <div className="wrapper">
                        <div className="center-vertically_">
                          <div className="padding">
                            <span className="monospace">{member.id}</span>
                          </div>
                        </div>
                      </div>
                    </Cell>
                    <Cell md={2} className="text-center">
                      <div className="padding">
                        <Avatar size="admin-user-list" user={member} />
                      </div>
                    </Cell>
                    <Cell md={12}>
                      <div className="padding">
                        <div className="admin-list-item-title">
                          {_c.getFullName(member)}
                        </div>
                        <div className="admin-list-item-subtitle">
                          {member.email}
                        </div>
                      </div>
                    </Cell>
                    <Cell md={4} className="text-center">
                      <div className="wrapper">
                        <div className="center-vertically_">
                          <div className="padding">
                            {member.registration_date}
                          </div>
                        </div>
                      </div>
                    </Cell>
                    <Cell md={4} className="text-center">
                      <div className="wrapper">
                        <div className="center-vertically_">
                          <div className="padding">
                            {member.last_visit_date}
                          </div>
                        </div>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </Link>
            </li>
          ))}
        </ul>
      </div>
    );
  }

  view__showListMobile() {
    return (
      <div className="show-for-small-only">
        <p>mobile</p>
      </div>
    );
  }

  search = () => {
    const { search, isSearching } = this.state;
    if (isSearching) return;

    this.setState({ isSearching: true });

    search.page = 1;
    //this.setState({ search });

    let updatedUrl = this.generateUrl(search);

    this.props.history.push(updatedUrl);
    this.init();
  };

  generateUrl = (search) => {
    let url = window.location.pathname;
    Object.keys(search).map((key, index) => {
      if (search[key]) {
        url = `${url}${index === 0 ? `?` : `&`}${key}=${search[key]}`;
      }
      return null;
    });

    return url;
  };

  handle__sendConfirmationMail = () => {
    const { search } = this.state;
    const _c = this.props.context;

    if (
      !window.confirm(
        "Möchtest Du allen ausgewählten Mitgliedern die Bestätigungsmail erneut zusenden?\r\n\r\nMitglieder, die bereits ein Passwort festgelegt haben, erhalten keine weitere E-Mail."
      )
    )
      return;

    this.setState({ sendingStatus: "SENDING" });

    let apiUrl = _c.apiEndpoints.adminMembersSendConfirmationMassMail;

    Object.keys(search).map((key, index) => {
      if (search[key]) {
        apiUrl = `${apiUrl}${index === 0 ? `?` : `&`}${key}=${search[key]}`;
      }
      return null;
    });

    if (_c.isDebug()) {
      console.log("apiUrl", apiUrl);
    }

    axios
      .post(
        apiUrl,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { totalSent } = apiResponseData;
            this.setState({ totalSent, sendingStatus: "SENT" });
          }
        } catch {}
      })
      .catch((error) => {
        this.setState({ sendingStatus: "ERROR" });
      });
  };

  init() {
    const { search } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiEndpoints.adminMembers;

    Object.keys(search).map((key, index) => {
      if (search[key]) {
        apiUrl = `${apiUrl}${index === 0 ? `?` : `&`}${key}=${search[key]}`;
      }
      return null;
    });

    if (_c.isDebug()) {
      console.log("apiUrl", apiUrl);
    }

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { members, pages, page, total, courses } = apiResponseData;
            this.setState(
              {
                members,
                pages,
                page,
                total,
                courses,
                status: "LOADED",
                isSearching: false,
              },
              () => {
                _c.initFinished();
              }
            );
          }
        } catch {}
      })
      .catch((error) => {});
  }
}

export default withContext(AdminMembers);
